<template>
  <CabinetPage :preloader="preloader">
    <div class="mx-cabinet-page-header">
      <router-link :to="{name: 'company.show', params: {id: $route.params.company_id}}" class="btn btn-outline-secondary">
        <ph-caret-left :size="22" />
      </router-link>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-6">
        <h4 class="mb-3">{{ $t('company.cad.subtitle') }}</h4>
        <form class="mx-form">
          <div class="mx-modal-form">
            <div class="mb-3">
              <div class="mx-modal-form__element">
                <label class="form-label">{{ company.name }}</label>
              </div>

              <div class="mx-modal-form__element" v-for="element in form_elements">
                <label class="form-label">{{ $t(`company.cad.${element}`) }}</label>
                <input type="text" class="form-control" v-model="form[element]">
                <div v-if="errors[element]" class="invalid-feedback">
                  {{ $t(`company.errors.${getErrorWord(errors[element][0])}`) }}
                </div>
              </div>

              <div class="mx-modal-form__element" style="margin-top: 20px;">
                <label class="mx-modal-form-switch">
                  <input type="checkbox" class="mx-modal-form-switch__input" v-model="form.is_active">
                  <span class="mx-modal-form-switch__slider mx-round"></span>
                </label>
                <label class="form-label">{{ $t(`company.cad.is_active`) }}</label>
              </div>

            </div>
          </div>
          <div class="mx-form-buttons">
            <router-link :disabled="button_disabled" tag="button" :to="{name: 'company.show', params: {id: $route.params.company_id}}" class="btn btn-peepz-default">
              {{ $t('company.abort') }}
            </router-link>
            <button :disabled="button_disabled" @click="save" type="button" class="btn btn-peepz-success">{{ $t('company.save') }}</button>
          </div>
        </form>
      </div>
    </div>
  </CabinetPage>
</template>

<script>

import CabinetPage from "@/components/main/CabinetPage";

import {
  PhPlus,
  PhLockLaminated,
  PhCaretLeft
} from "phosphor-vue";

export default {
  components: {CabinetPage, PhPlus, PhLockLaminated, PhCaretLeft},
  data() {
    return {
      button_disabled: false,
      preloader: true,
      company: {
        'id': null,
        'name': ''
      },
      form_elements: [
        'title',
        'description',
        'url',
        'login',
        'password'
      ],
      form: {
        company_id: null,
        title: '',
        description: '',
        url: '',
        login: '',
        password: '',
        is_active: 1,
      },
      errors: [],
    }
  },
  mounted() {
    this.getCompany()
  },
  methods: {
    getCompany() {
      this.$store.dispatch('companies').then(() => {
        const company = this.$store.getters.companies.find(company => company.id === parseInt(this.$route.params.company_id))
        this.company.name = company.company_name
        this.form.company_id = company.id
        this.preloader = false
      })
    },
    save() {
      this.button_disabled = true;
      this.errors = []
      this.$store.dispatch('cadCreate', this.form).then(() => {
        this.$router.replace({
          name: 'company.show', params: {id: this.form.company_id}
        })
      }).catch(error => {
        this.button_disabled = false;
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data;
            break;
        }
      })
    },
    getErrorWord(str) {
      return str.match(/\b(required|number|valid)\b/g)[0]
    }
  }
}
</script>
